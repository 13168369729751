<template>
  <div class="fiat-transaction-table-container">
    <CCard class="fiat-transaction-list-table-card">
      <CDataTable :items="items" :fields="fields" fixed hover striped bordered>
        <template ##="{item, index}">
          <td>{{ index + 1 + (activePage - 1) * limit }}</td>
        </template>
        <template #created_at="{item}">
          <td>{{ item.created_at | moment(dateFormat) }}</td>
        </template>
        <template #from_currency="{item}">
          <td style="text-align: center">
            {{ item.from_currency }}
          </td>
        </template>
        <template #to_cryptocurrency="{item}">
          <td style="text-align: center">
            {{ item.to_cryptocurrency }}
          </td>
        </template>
        <template #payment_method="{item}">
          <td style="text-align: center">
            <StakingBadge type="fiat-transaction-payment-method" :text="item.payment_method" />
          </td>
        </template>
        <template #from_amount="{item}">
          <td style="text-align: center">
            {{ item.from_amount }}
          </td>
        </template>
        <template #to_amount="{item}">
          <td style="text-align: center">
            {{ item.to_amount }}
          </td>
        </template>
        <template #provider="{item}">
          <td style="text-align: center">
            {{ item.provider }}
          </td>
        </template>
        <template #transaction_id="{item}">
          <td style="text-align: center">
            <a :href="getTxLink(item.transaction_id, item.provider, item.transaction_type)" class="btn-link" target="_blank" rel="noopener noreferrer">{{ item.transaction_id }}</a>
          </td>
        </template>
        <template #status="{item}">
          <td style="text-align: center">
            <StakingBadge type="fiat-transaction-status" :text="item.status" />
          </td>
        </template>
        <template #action="{item}">
          <td width="50px">
            <button class="btn btn-view-details" @click="viewDetails(item)">
              <img src="@/assets/images/ico-edit-user.svg" alt class="hand edit-icon" />
            </button>
          </td>
        </template>
      </CDataTable>
    </CCard>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import StakingBadge from '@/components/StakingBadge';
import { Constants, env } from '@/constants';

export default {
  name: 'FiatTransactionListTable',
  components: {
    StakingBadge,
  },
  data() {
    return {
      fiatTrackUrl: env.wyreTrackUrl,
      dateFormat: Constants.dateFormat,
      fields: [
        '#',
        {
          key: 'created_at',
          label: this.$t('FIAT_TRANSACTION_LIST_TABLE_COMPONENT_DATE_COL'),
          _style: 'min-width: 160px; text-align: left',
        },
        {
          key: 'member_email',
          label: this.$t('FIAT_TRANSACTION_LIST_TABLE_COMPONENT_EMAIL_COL'),
          _style: 'min-width: 180px;text-align: left;',
        },
        {
          key: 'from_currency',
          label: this.$t('FIAT_TRANSACTION_LIST_TABLE_COMPONENT_FROM_CURRENCY_COL'),
          _style: 'min-width: 140px; text-align: center;',
        },
        {
          key: 'to_cryptocurrency',
          label: this.$t('FIAT_TRANSACTION_LIST_TABLE_COMPONENT_TO_CRYPTOCURRENCY_COL'),
          _style: 'min-width: 140px; text-align: center;',
        },
        {
          key: 'payment_method',
          label: this.$t('FIAT_TRANSACTION_LIST_TABLE_COMPONENT_PAYMENT_METHOD_COL'),
          _style: 'text-align:center; min-width: 160px;',
        },
        {
          key: 'from_amount',
          label: this.$t('FIAT_TRANSACTION_LIST_TABLE_COMPONENT_FROM_AMOUNT_COL'),
          _style: 'text-align:center; min-width: 120px;',
        },
        {
          key: 'to_amount',
          label: this.$t('FIAT_TRANSACTION_LIST_TABLE_COMPONENT_TO_AMOUNT_COL'),
          _style: 'text-align:center; min-width: 120px;',
        },
        {
          key: 'transaction_id',
          label: this.$t('FIAT_TRANSACTION_LIST_TABLE_COMPONENT_TRANSACTION_ID_COL'),
          _style: 'text-align:left; min-width: 160px;',
        },
        {
          key: 'status',
          label: this.$t('FIAT_TRANSACTION_LIST_TABLE_COMPONENT_STATUS_COL'),
          _style: 'text-align:center',
        },
        {
          key: 'provider',
          label: this.$t('FIAT_TRANSACTION_LIST_TABLE_COMPONENT_PROVIDER_COL'),
          _style: 'text-align:center',
        },
        {
          key: 'action',
          label: this.$t('FIAT_TRANSACTION_LIST_TABLE_COMPONENT_ACTION_COL'),
        },
      ],
    };
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    activePage: {
      type: Number,
      default: 0,
    },
    limit: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    viewDetails(item) {
      this.$router.push(`fiat-transactions/${item.id}/details`);
    },
    _formatAmount(value) {
      if (!value) {
        return 0;
      }
      var formatter = new Intl.NumberFormat('en-US', {
        maximumFractionDigits: 5,
        minimumFractionDigits: 0 }).format(value);
      return formatter;
    },
    getTxLink(tx_id, provider, tx_type){ 
      let url = '';
      switch (provider.toUpperCase()){
        case 'SENDWYRE':
          url = this.fiatTrackUrl + tx_id;
          break;
        case 'MOONPAY':
          url = env.moonpaySellUrl + `?transactionId=${tx_id}`;
          if (tx_type != 'sell') {
            url = env.moonpayBuyUrl  + `?transactionId=${tx_id}`;
          }
          break;
      };
      return url;
    }
  },
};
</script>
<style lang="scss">
.fiat-transaction-table-container {
  .fiat-transaction-list-table-card {
    .table {
      margin-bottom: 0;
      .hand {
        cursor: pointer;
      }
      .btn {
        font-size: 14px;
        font-weight: 400;
        color: #0505a5;
        outline: none;
        padding-top: 0;
        padding-bottom: 0;
      }

      .currency-image {
        width: 30px;
      }

      .fixed-rate-icon {
        color: #5da30b;
      }
    }
  }
}
</style>
