<template>
  <div class="fiat-transaction-container wrapper">
    <CCard>
      <CCardHeader>
        <CRow>
          <CCol md="12" sm="12">
            <h1>{{ $t('FIAT_TRANSACTION_LIST_PAGE_TITLE') }}</h1>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
        <SearchFiatTransctionHeader :downloadCSV="downloadCSV" :onSearch="onSearch" :statuses="statuses" :paymentMethods="paymentMethods" :providers="providers"/>
        <FiatTransactionListTable :limit="limit" :activePage="activePage" :items="items" :total="total" />
        <StakingPagination
          :limit="limit"
          :total="total"
          :activePage="activePage"
          @changePage="onChangePage"
          @changePageSize="onChangePageSize"
        />
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import SearchFiatTransctionHeader from '@/components/FiatTransaction/SearchFiatTransactionHeader';
import FiatTransactionListTable from '@/components/FiatTransaction/FiatTransactionListTable';
import StakingPagination from '@/components/StakingPagination';
import stringHelper from '@/utils/string-helper';
import { endpoints, env, Constants } from '@/constants';
import responseHelper from '@/utils/response-helper';
import sessionStorageHelper from '@/utils/sessionStorage-helper';
import dateTimeHelper from '@/utils/datetime-helper';
import { saveAs } from 'file-saver';

export default {
  name: 'FiatTransactionList',
  components: {
    SearchFiatTransctionHeader,
    FiatTransactionListTable,
    StakingPagination
  },
  data() {
    return {
      items: [],
      paymentMethods: [],
      statuses: [],
      providers: [],
      filterData: {},
      total: 0,
      limit: env.app.pageSize,
      activePage: 1,
      dateFormat: Constants.dateFormat,
    };
  },
  computed: {
    offset() {
      return (this.activePage - 1) * this.limit;
    },
    pages() {
      return Math.ceil(this.total / this.limit);
    },
  },
  async mounted() {
    await Promise.all([this.getFiatTransactionStatues(),this.getFiatTransactionPaymentMethods(),this.getFiatTransactionProviders()]);
    this.isLoading = false;
  },
  methods: {
    async getFiatTransactionStatues() {
      try {
        const result = await this.$http.get(endpoints.getFiatTransactionStatues);
        this.statuses = stringHelper.translateTextForDropdownList(result.data, 'FIAT_TRANSACTION_STATUS');
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('FIAT_TRANSACTION_LIST_PAGE_ERROR_TITLE'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    async getFiatTransactionPaymentMethods() {
      try {
        const result = await this.$http.get(endpoints.getFiatTransactionPaymentMethods);
        this.paymentMethods = stringHelper.translateTextForDropdownList(result.data, 'FIAT_TRANSACTION_PAYMENT_METHOD');
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('FIAT_TRANSACTION_LIST_PAGE_ERROR_TITLE'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    async getFiatTransactionProviders() {
      try {
        const result = await this.$http.get(endpoints.getFiatTransactionProviders);
        this.providers = stringHelper.translateTextForDropdownList(result.data, 'FIAT_TRANSACTION_PROVIDER');
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('FIAT_TRANSACTION_LIST_PAGE_ERROR_TITLE'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    async searchFiatTransactions(data) {
      data = data || {};
      this.filterData = {
        ...this.filterData,
        ...data,
      };
      const filterData = this.filterData;

      try {
        const params = {
          limit: this.limit,
          offset: this.offset,
          email: filterData.email,
          from_date: filterData.dateRange.startDate,
          to_date: dateTimeHelper.adjustToDate(filterData.dateRange.endDate),
          from_currency: filterData.from_currency,
          to_cryptocurrency: filterData.to_cryptocurrency,
          payment_method: filterData.payment_method,
          status: filterData.status,
          provider: filterData.provider
        };
        const result = await this.$http.get(endpoints.searchFiatTransactions, { params });

        this.items = result.data.items || [];
        this.total = result.data.total;
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('FIAT_TRANSACTION_LIST_PAGE_ERROR_TITLE'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },

    async onChangePage(page) {
      this.activePage = page;

      this.searchFiatTransactions();
    },
    async onChangePageSize(value) {
      this.limit = value;
      this.activePage = 1;

      this.searchFiatTransactions();
    },
    async onSearch(searchData) {
      const data = searchData;
      this.activePage = 1;

      this.searchFiatTransactions(data);
    },

    async downloadCSV() {
      const filterData = {
        ...this.filterData,
      };
      const params = {
          limit: this.limit,
          offset: this.offset,
          email: filterData.email,
          from_date: filterData.dateRange.startDate,
          to_date: dateTimeHelper.adjustToDate(filterData.dateRange.endDate),
          from_currency: filterData.from_currency,
          to_cryptocurrency: filterData.to_currency,
          payment_method: filterData.payment_method,
          status: filterData.status,
          provider: filterData.provider
        };
      try {
        const result = await this.$http.get(endpoints.downloadFiatTransactionsCSV, { params });
        const data = result;
        const fileUrl = 'fiat-transaction.csv';
        const contentType = 'application/octet-stream';
        const file = new Blob([data], { type: contentType });
        saveAs(file, fileUrl);
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('FIAT_TRANSACTION_LIST_PAGE_ERROR_TITLE'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
  }
};
</script>
<style lang="scss">
.fiat-transaction-container {
  padding: 0 24px;
  @media screen and (max-width: 1024px) {
    padding: 0 15px;
  }

  .card {
    > .card-header {
      border-bottom: none;
      padding-bottom: 0px;

      h1 {
        color: #657187;
        font-size: 18px;
        font-weight: 600;
        line-height: 34px;
        margin-bottom: 0px;
      }
    }
  }
}
</style>
